import fetch from 'auth/FetchInterceptor'

export const getIntegrations = () => {
  return fetch({
    url: `login-integration?sort=createdAt,DESC`,
    method: 'get',
  })
    .then((data) => data?.data || [])
    .catch((err) => console.log(err))
}

export const createOneIntegration = (data) => {
  return fetch({
    url: 'login-integration',
    method: 'post',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const updateOneIntegration = ({ id, data }) => {
  return fetch({
    url: `login-integration/${id}`,
    method: 'patch',
    data,
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const deleteOneIntegration = (id) => {
  return fetch({
    url: `login-integration/${id}`,
    method: 'delete',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getZohoWorkdriveListing = (offset) => {
  let offsetUrl = ''
  if (offset) offsetUrl = `?offset=${offset}`
  return fetch({
    url: `zoho-workdrive-folders/zoho-listing${offsetUrl}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getAllZohoWorkdriveFolder = () => {
  return fetch({
    url: `zoho-workdrive-folders`,
    method: 'get',
  })
    .then((data) => data?.data || [])
    .catch((err) => console.log(err))
}
export const downloadSPMetadata = () => {
  return fetch({
    url: 'saml/v2/metadata',
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getGuacamoleIntegration = () => {
  return fetch({
    url: 'login-integration?filter=name||eq||Guacamole&filter=isEnabled||eq||true',
    method: 'get',
  })
    .then((data) => data?.data?.[0])
    .catch((err) => console.log(err))
}

export const shiftManagement = (id) => {
  return fetch({
    url: `task-scheduler/shift/${id}`,
    method: 'patch',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
