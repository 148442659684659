import fetch from 'auth/FetchInterceptor'
import { TableSorting } from 'utils/enum'
import { formatFiltersForRest } from 'utils/filter'

export const getAllViolations = (pageSize, pageNumber, filters, sorting, isNew) => {
  let localFilters = { ...filters }

  if (!localFilters) localFilters = { status: { [isNew ? 'in' : 'notin']: ['New'] } }
  if (localFilters && !localFilters?.status)
    localFilters.status = { [isNew ? 'in' : 'notin']: ['New'] }

  let formattedFilters = formatFiltersForRest(localFilters)

  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`
  return fetch({
    url: `access-violation?join=client&limit=${pageSize}&page=${pageNumber}${sortingStr}${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getAllViolationsNEW = (pageSize, pageNumber, filters, sorting) => {
  let formattedFilters = formatFiltersForRest(filters)
  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`
  return fetch({
    url: `access-violation?join=client&limit=${pageSize}&page=${pageNumber}${sortingStr}${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getViolationForDashboard = ({ last7Day, search = '', groupId }) => {
  return fetch({
    url: `access-violation?fields=policy,client,createdAt,updatedAt,status,restrictedResource,restrictedResourceName,classificationName&join=client||login,groupId&sort=updatedAt,DESC&filter=updatedAt||gte||${last7Day}&filter=policy||notnull${
      search && `&filter=client.login||$contL||${search}`
    }${groupId && `&filter=groupId||eq||${groupId}`}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}
export const getViolationResolvedForDashboard = ({ last7Day }) => {
  return fetch({
    url: `access-violation?fields=policy,createdAt,status&sort=createdAt,DESC&filter=createdAt||gte||${last7Day}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const getDailyAlerts = ({ count, search, groupId }) => {
  return fetch({
    url: `dashboard/daily-alerts?count=${count}${search && `&filter=search||eq||${search}`}${
      groupId && `&filter=groupId||eq||${groupId}`
    }`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const updateAccessViolation = ({ id, info }) => {
  return fetch({
    url: `access-violation/${id}`,
    method: 'patch',
    data: {
      ...info,
    },
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const deleteOneAccessViolation = (id) => {
  return fetch({
    url: `access-violation/${id}`,
    method: 'delete',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const downloadAlertAsCsv = () => {
  return fetch({
    url: 'access-violation/export-csv',
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getNewAlertsCount = () => {
  return fetch({
    url: 'access-violation?filter=status||eq||New&fields=id,status&filter=classificationName||$exclL||Alert file download&filter=policy||notin||Login,Logout,Uninstall,WeakPassword&filter=classificationName||notin||Login,Logout,Uninstall,WeakPassword',
    method: 'get',
  })
    .then((data) => data?.data?.length || 0)
    .catch((err) => console.log(err))
}
